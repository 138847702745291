<script lang="ts">
    import type { BigAdFragment } from '$gen/graphql';
    import { routes } from '$lib/routes';

    export let ad: BigAdFragment;
</script>

<article>
    {#each ad.advertisingImage as banner}
        {#if banner.__typename === 'assets_Asset'}
            <a
                href={ad.externalLink}
                target="_blank"
                rel="external"
                on:click={async () => fetch(routes.api_countClick(ad.id))}
            >
                {#if banner.kind === 'image'}
                    <img
                        class="h-auto w-full"
                        src={banner.url}
                        alt={banner.title}
                        width={banner.width}
                        height={banner.height}
                        loading="lazy"
                    />
                {:else if banner.kind === 'video'}
                    <video
                        class="h-auto w-full"
                        src={banner.url}
                        alt={banner.title}
                        width={banner.width}
                        height={banner.height}
                        loading="lazy"
                        autoplay
                        loop
                        muted
                    />
                {/if}
            </a>
        {/if}
    {/each}
</article>
