<script lang="ts">
    import type { BigAdFragment } from '$gen/graphql';
    import BigInserat from './BigInserat.svelte';

    export let ads: BigAdFragment[];
</script>

{#if ads && ads.length > 0}
    <div class="inserat-box col-span-full -mb-5 h-5 text-center text-xs">Advertisement</div>
    <div class="col-start-2 contents">
        {#each new Array(3) as _, index}
            {@const ad = ads[index]}
            {#if ad}
                {#if index === 2}
                    <div class="block lg:hidden xl:block">
                        <BigInserat {ad} />
                    </div>
                {:else}
                    <BigInserat {ad} />
                {/if}
            {:else if index === 1}
                <div class="hidden lg:block" />
            {:else}
                <!-- Filling element to prevent a non-advertising element from occurring on the same row. -->
                <div class="hidden xl:block" />
            {/if}
        {/each}
    </div>
{/if}
